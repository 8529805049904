// MainSection.js
import React from 'react';
import Image from 'next/image';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import TermsAndConditions from '@/components/shared/footer/TermsAndConditions';
import FeatureItem from './FeatureItem';
import Testimonial from './Testimonial';
import { PawPrint, Mail, Lock } from 'lucide-react';

export function Landing2() {
  return (
    <main className='flex-1'>
      <HeroSection />
      <FeaturesSection />
      <TestimonialsSection />
    </main>
  );
}

function HeroSection() {
  return (
    <section className='w-full py-12 md:py-24'>
      <div className='container mx-auto px-4'>
        <div className='flex flex-col items-center space-y-4 text-center'>
          <h1 className='text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl'>Keep Your Training on Track</h1>
          <p className='mx-auto max-w-lg text-gray-500'>
            BarkOnTrack helps you manage your clients&apos; training schedules, track progress, and achieve your goals
            as a trainer.
          </p>
          <div className='w-full max-w-md'>
            <a
              href='https://8naqjmqrv07.typeform.com/to/KfIuMeYb'
              target='_blank'
              rel='noopener noreferrer'
              className='flex items-center justify-center space-x-2 rounded bg-blue-500 p-3 text-white hover:bg-blue-600'
            >
              <span>Sign Up</span>
            </a>
            <p className='mt-2 text-xs text-gray-500'>Sign up to get notified when we launch.</p>
          </div>
          <div className='mt-8 w-full max-w-xl'>
            <Image
              src='/wade-austin-ellis-FtuJIuBbUhI-unsplash.jpg'
              width={800}
              height={400}
              alt='Happy dog with owner'
              className='w-full rounded-lg object-cover'
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function FeaturesSection() {
  return (
    <section className='w-full bg-gray-100 py-12'>
      <div className='container mx-auto px-4'>
        <h2 className='mb-12 text-center text-3xl font-bold tracking-tighter sm:text-4xl'>Features</h2>
        <div className='grid gap-6 sm:grid-cols-2 md:grid-cols-3'>
          <FeatureItem
            icon={<PawPrint />}
            title='Customized Training Plans'
            description="Create personalized training schedules tailored to your clients' needs."
          />
          <FeatureItem
            icon={<Mail />}
            title='Progress Tracking'
            description="Monitor your clients' improvement with detailed progress reports."
          />
          <FeatureItem
            icon={<Lock />}
            title='Expert Tips & Advice'
            description='Access professional dog training resources and guidance.'
          />
        </div>
      </div>
    </section>
  );
}

function TestimonialsSection() {
  return (
    <section className='w-full py-12'>
      <div className='container mx-auto px-4'>
        <h2 className='mb-12 text-center text-3xl font-bold tracking-tighter sm:text-4xl'>What Our Users Say</h2>
        <div className='grid gap-6 sm:grid-cols-2 md:grid-cols-3'>
          <Testimonial
            name='Sarah M.'
            quote='BarkOnTrack has made managing my clients and scheduling effortless. It streamlines everything so I can focus more on training.'
          />
          <Testimonial
            name='Mike R.'
            quote="The platform allows me to keep track of client progress and schedule sessions with ease. It's become an essential tool in my business."
          />
          <Testimonial
            name='Emily L.'
            quote="With BarkOnTrack, I can easily manage my clients' schedules and track their progress in one place. It keeps everything organized and professional."
          />
        </div>
      </div>
    </section>
  );
}
