'use client';

import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import useLoginStore from '@/stores/useLoginStore';
import { useToast } from '@/hooks/use-toast';
import { useSession } from 'next-auth/react';
import { UserMenu } from '@/components/shared/usermenu/UserMenu';
import { User } from '@/types/User';

export function Header2() {
  const { data: session, status } = useSession();
  return (
    <header className='flex h-14 items-center px-4 lg:px-6'>
      <Link className='flex items-center justify-center' href='/'>
        <Image src={'/banner2.png'} alt={''} width={150} height={50} />
        <span className='sr-only'>BarkOnTrack</span>
      </Link>
      <div className='ml-auto flex gap-2'>
        {status === 'loading' ? (
          <Button variant='outline' disabled loading className='btn-disabled border-none' />
        ) : session?.user ? (
          <UserMenu user={session.user as User} />
        ) : (
          <>
            <Popover>
              <PopoverTrigger asChild>
                <Button variant='outline'>Sign In</Button>
              </PopoverTrigger>
              <PopoverContent>
                <LoginForm />
              </PopoverContent>
            </Popover>
          </>
        )}
      </div>
    </header>
  );
}

function LoginForm() {
  const { email, password, requestInProgress, setEmail, setPassword, setRequestInProgress } = useLoginStore();
  const { toast } = useToast();
  const router = useRouter();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRequestInProgress(true);
    try {
      const res = await signIn('credentials', {
        username: email,
        password: password,
        redirect: false,
        callbackUrl: '/',
      });
      if (res?.error) {
      } else if (res?.ok) {
        router.push('/dashboard');
        window.location.href = '/dashboard';
      }
    } catch (error) {}
    setRequestInProgress(false);
  };

  return (
    <div className='flex flex-col space-y-4'>
      <h2 className='justify-right text-xl font-bold'>Login</h2>
      <form onSubmit={handleSubmit} className='space-y-4 py-4'>
        <div>
          <Label className='block text-sm font-medium text-gray-700 dark:text-gray-300' htmlFor='email'>
            Email
          </Label>
          <Input
            className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500'
            id='email'
            placeholder='name@example.com'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <div className='flex items-center justify-between'>
            <Label className='block text-sm font-medium text-gray-700 dark:text-gray-300' htmlFor='password'>
              Password
            </Label>
            <Link
              className='text-sm font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200'
              href='/forgot-password'
            >
              Forgot Password?
            </Link>
          </div>
          <Input
            className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500'
            id='password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <Button loading={requestInProgress} disabled={requestInProgress} className='w-full' type='submit'>
            Login
          </Button>
        </div>
      </form>
    </div>
  );
}
