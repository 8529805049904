import React from 'react';

export default function FeatureItem({ icon, title, description }) {
  return (
    <div className='flex flex-col items-center space-y-2 p-4'>
      {icon}
      <h3 className='text-xl font-bold'>{title}</h3>
      <p className='text-center text-sm text-gray-500'>{description}</p>
    </div>
  );
}
