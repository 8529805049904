import React from 'react';

export default function Testimonial({ name, quote }) {
  return (
    <div className='flex flex-col items-center space-y-2 p-4'>
      <p className='text-center text-sm italic text-gray-500'>&quot;{quote}&quot;</p>
      <p className='text-sm font-bold'>- {name}</p>
    </div>
  );
}
